import React, { useEffect } from "react"
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core"

import ScrollAnimate from "../animate/ScrollAnimate"
import Mock from "./genesis-mock.png"

const ArtInt = () => {
  const classes = useStyles()

  return (
    <section>
      <Container maxWidth="xl">
        <Box py={10} position="relative">
          <Grid container>
            <Grid item md={6}>
              <ScrollAnimate
                visible={{ opacity: 1, scale: 1 }}
                hidden={{ opacity: 0, scale: 0 }}
              >
                <img src={Mock} alt="" width="100%" />
              </ScrollAnimate>
            </Grid>

            <Grid item md={6}>
              <Box className="ai-card">
                <Box className={classes.marginLeft}>
                  <Box pt={5} />
                  <Typography variant="h2" color="primary" gutterBottom>
                    <Box maxWidth={500}>
                      <b>GENESIS</b>
                    </Box>
                  </Typography>
                  <Typography variant="h5" color="primary" gutterBottom>
                    <Box maxWidth={500}>
                      <b>An Exception-based Operations Management Solution</b>
                    </Box>
                  </Typography>

                  <Typography variant="subtitle1">
                    <Box maxWidth={400} mt={5} className={classes.marginLeft}>
                      It harnesses the power of IoT and Analytics to give you
                      actionable inputs which eases your operations.
                      Consolidates information from multiple devices and
                      locations, it highlights discrepancies with alerts,
                      reports and analytics
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  )
}

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: 40,
    paddingTop: 15,
    maxWidth: 520,
    minHeight: 100,
    transition: "transform 0.15s ease-in-out",
    backgroundColor: "#0f123d",
    color: "#fff",
    borderRadius: 17,
    "&:hover": {
      transform: "scale3d(1.05, 1.05, 1)",
      boxShadow: `0 0 12px 1px rgb(0 10 10 / 70%)`,
    },
  },
  image: {
    position: "absolute",
    top: 2,
    right: 10,
  },
  marginLeft: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 80,
    },
  },
}))

export default ArtInt
