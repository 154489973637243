import React, { useEffect } from "react"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

const ScrollAnimate = ({ children, visible, hidden, style }) => {
  const [ref, inView] = useInView()
  const animation = useAnimation()

  useEffect(() => {
    if (inView) {
      animation.start("visible")
    } else {
      animation.start("hidden")
    }
  }, [animation, inView])

  return (
    <motion.div
      style={style}
      ref={ref}
      animate={animation}
      initial="hidden"
      transition={{ ease: "easeOut", duration: 0.3 }}
      variants={{
        visible,
        hidden,
      }}
    >
      {children}
    </motion.div>
  )
}

export default ScrollAnimate
