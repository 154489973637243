import React from "react"
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { Link as GatsbyLink, navigate } from "gatsby"

import "./offerings.css"
import process from "./off-images/process.svg"
import dataengg from "./off-images/data.svg"
import ai from "./off-images/ai.svg"
import rpa from "./off-images/rpa.svg"
import analytics from "./off-images/analytics.svg"
import software from "./off-images/software.svg"
import ScrollAnimate from "../animate/ScrollAnimate"

const Offerings = () => {
  const classes = useStyles()

  return (
    <Box mt={3} mb={10} className="offerings">
      <Container maxWidth="lg">
        <Box textAlign="center" mb={15}>
          <Typography variant="h2" color="primary" gutterBottom>
            <b>Our Offerings</b>
          </Typography>
        </Box>

        <Grid container spacing={3} className={classes.marginLeft}>
          <Grid item md={4}>
            <div className="offering-item">
              <ScrollAnimate
                style={{ height: "100%" }}
                visible={{ x: 0 }}
                hidden={{ x: 100 }}
              >
                <Box position="absolute" zIndex={0} top={10} right={30}>
                  <img
                    src={process}
                    alt="Process Consulting"
                    style={{
                      height: 300,
                      opacity: 0.6,
                    }}
                  />
                </Box>
                <Card
                  elevation={10}
                  className="offering-card"
                  onClick={() => navigate("/services")}
                >
                  <CardContent className="offering-content">
                    <Box mt={3}>
                      <Typography variant="h4" color="inherit" gutterBottom>
                        <b>PROCESS CONSULTING</b>
                      </Typography>
                      <Typography variant="body1" color="primary" gutterBottom>
                        <Box color="#333">
                          Our process consultants carry extensive expertise in
                          Six Sigma, Lean practices, Project Management across
                          geographies and clients. We work with you to improve
                          processes and thereby profitability!
                        </Box>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </ScrollAnimate>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="offering-item">
              <ScrollAnimate
                style={{ height: "100%" }}
                visible={{ x: 0 }}
                hidden={{ x: 100 }}
              >
                <Box position="absolute" zIndex={0} top={10} right={30}>
                  <img
                    src={analytics}
                    alt="Analytics"
                    style={{
                      height: 300,
                      opacity: 0.6,
                    }}
                  />
                </Box>
                <Card
                  elevation={10}
                  className="offering-card"
                  onClick={() => navigate("/services")}
                >
                  <CardContent className="offering-content">
                    <Box mt={3}>
                      <Typography variant="h4" color="inherit" gutterBottom>
                        <b>ANALYTICS</b>
                      </Typography>
                      <Typography variant="body1" color="primary" gutterBottom>
                        <Box color="#333">
                          Get deeper insights from your data to make more
                          effective decisions. Evaluate scenarios, get alerts,
                          and do much more from our customizable analytics
                          platform
                        </Box>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </ScrollAnimate>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="offering-item">
              <ScrollAnimate
                style={{ height: "100%" }}
                visible={{ x: 0 }}
                hidden={{ x: 100 }}
              >
                <Box position="absolute" zIndex={0} top={10} right={30}>
                  <img
                    src={dataengg}
                    alt="Data Engineering"
                    style={{
                      height: 300,
                      opacity: 0.6,
                    }}
                  />
                </Box>
                <Card
                  elevation={10}
                  className="offering-card"
                  onClick={() => navigate("/services")}
                >
                  <CardContent className="offering-content">
                    <Box mt={3}>
                      <Typography variant="h4" color="inherit" gutterBottom>
                        <b>DATA ENGINEERING</b>
                      </Typography>
                      <Typography variant="body1" color="primary" gutterBottom>
                        <Box color="#333">
                          Your decisions are only as good as your underlying
                          data. Talk to us to get the right metrics, data
                          capture system and implement data audit processes.
                        </Box>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </ScrollAnimate>
            </div>
          </Grid>
        </Grid>

        <Box pt={7} />

        <Grid container spacing={3} className={classes.marginLeftMinus}>
          <Grid item md={4}>
            <div className="offering-item">
              <ScrollAnimate
                style={{ height: "100%" }}
                visible={{ x: 0 }}
                hidden={{ x: -100 }}
              >
                <Box position="absolute" zIndex={0} top={10} right={30}>
                  <img
                    src={rpa}
                    alt="RPA"
                    style={{
                      height: 300,
                      opacity: 0.6,
                    }}
                  />
                </Box>
                <Card
                  elevation={10}
                  className="offering-card"
                  onClick={() => navigate("/services")}
                >
                  <CardContent className="offering-content">
                    <Box mt={3}>
                      <Typography variant="h4" color="inherit" gutterBottom>
                        <b>ROBOTIC PROCESS AUTOMATION</b>
                      </Typography>
                      <Typography variant="body1" color="primary" gutterBottom>
                        <Box color="#333">
                          Our experts help you identify, design and deploy the
                          automation opportunities that gives you the right ROI.
                        </Box>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </ScrollAnimate>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="offering-item">
              <ScrollAnimate
                style={{ height: "100%" }}
                visible={{ x: 0 }}
                hidden={{ x: -100 }}
              >
                <Box position="absolute" zIndex={0} top={10} right={30}>
                  <img
                    src={ai}
                    alt="AI"
                    style={{
                      height: 300,
                      opacity: 0.6,
                    }}
                  />
                </Box>
                <Card
                  elevation={10}
                  className="offering-card"
                  onClick={() => navigate("/services")}
                >
                  <CardContent className="offering-content">
                    <Box mt={3}>
                      <Typography variant="h4" color="inherit" gutterBottom>
                        <b>ARTIFICIAL INTELLIGENCE</b>
                      </Typography>
                      <Typography variant="body1" color="primary" gutterBottom>
                        <Box color="#333">
                          Explore what we can do in the space of Artificial
                          Intelligence for your use case.
                        </Box>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </ScrollAnimate>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="offering-item">
              <ScrollAnimate
                style={{ height: "100%" }}
                visible={{ x: 0 }}
                hidden={{ x: -100 }}
              >
                <Box position="absolute" zIndex={0} top={10} right={30}>
                  <img
                    src={software}
                    alt="Software"
                    style={{
                      height: 300,
                      opacity: 0.6,
                    }}
                  />
                </Box>
                <Card
                  elevation={10}
                  className="offering-card"
                  onClick={() => navigate("/services")}
                >
                  <CardContent className="offering-content">
                    <Box mt={3}>
                      <Typography variant="h4" color="inherit" gutterBottom>
                        <b>CUSTOM SOFTWARE</b>
                      </Typography>
                      <Typography variant="body1" color="primary" gutterBottom>
                        <Box color="#333">
                          We build customised web and mobile applications.
                        </Box>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </ScrollAnimate>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  marginLeft: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 60,
    },
  },
  marginLeftMinus: {
    [theme.breakpoints.up("md")]: {
      marginLeft: -60,
    },
  },
}))

export default Offerings
