import React, { useEffect } from "react"
import { Link as GatsbyLink } from "gatsby"
import clsx from "clsx"
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles,
  Typography,
  Button,
} from "@material-ui/core"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

import ScrollAnimate from "../animate/ScrollAnimate"
import Growth from "./grow.svg"
import Support from "./support.svg"
import Money from "./money.svg"

const ArtInt = () => {
  const classes = useStyles()
  const [ref, inView] = useInView()
  const animation = useAnimation()

  useEffect(() => {
    if (inView) {
      animation.start("visible")
    } else {
      animation.start("hidden")
    }
  }, [animation, inView])

  return (
    <section>
      <Container maxWidth="xl">
        <Box py={10} pt={20}>
          <Grid container alignItems="center">
            <Grid item md={6}>
              <Box className={classes.marginLeft}>
                <Typography variant="h2" color="primary" gutterBottom>
                  <Box maxWidth={500}>
                    <b>Leverage AI for Your Business</b>
                  </Box>
                </Typography>

                <Typography variant="subtitle1">
                  <Box maxWidth={400} className={classes.marginLeft}>
                    With the advent of powerful Machine Learning algorithms and
                    ease of access to computing capabilities companies are now
                    focusing on top opportunities for AI implementation. We
                    throw light on some of the key applications here.
                  </Box>
                </Typography>

                <Box pt={5} mb={10} className={classes.marginLeft}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    component={GatsbyLink}
                    to="/artificial-intelligence"
                  >
                    Learn more
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item md={6} style={{ width: "100%" }}>
              <Box>
                <ScrollAnimate visible={{ x: 0 }} hidden={{ x: 300 }}>
                  <Card className={classes.card}>
                    <CardContent style={{ position: "relative" }}>
                      <img
                        className={classes.image}
                        src={Growth}
                        alt=""
                        width={150}
                      />

                      <Box style={{ marginTop: 45 }}>
                        <Typography
                          variant="h5"
                          color="inherit"
                          gutterBottom
                          style={{ marginRight: 100 }}
                        >
                          Increase In Sale
                        </Typography>
                        {/* <Typography variant="subtitle2" color="inherit">
                          <Box color="rgba(212,214,255,.95)">
                            Esistono innumerevoli variazioni
                          </Box>
                        </Typography> */}
                      </Box>
                    </CardContent>
                  </Card>
                </ScrollAnimate>

                <ScrollAnimate visible={{ x: 0 }} hidden={{ x: 300 }}>
                  <Card
                    className={clsx(classes.card, classes.card2)}
                    data-sal="slide-left"
                    data-sal-delay="400"
                    data-sal-easing="ease"
                  >
                    <CardContent style={{ position: "relative" }}>
                      <img
                        className={classes.image}
                        src={Money}
                        alt=""
                        width={150}
                      />

                      <Box style={{ marginTop: 45 }}>
                        <Typography
                          variant="h5"
                          color="inherit"
                          gutterBottom
                          style={{ marginRight: 100 }}
                        >
                          Optimized Costs
                        </Typography>
                        {/* <Typography variant="subtitle2" color="inherit">
                          <Box color="rgba(212,214,255,.95)">
                            Esistono innumerevoli variazioni
                          </Box>
                        </Typography> */}
                      </Box>
                    </CardContent>
                  </Card>
                </ScrollAnimate>

                <ScrollAnimate visible={{ x: 0 }} hidden={{ x: 300 }}>
                  <Card
                    className={clsx(classes.card, classes.card3)}
                    data-sal="slide-left"
                    data-sal-delay="500"
                    data-sal-easing="ease"
                  >
                    <CardContent style={{ position: "relative" }}>
                      <img
                        className={classes.image}
                        src={Support}
                        alt=""
                        width={150}
                      />

                      <Box style={{ marginTop: 45 }}>
                        <Typography
                          variant="h5"
                          color="inherit"
                          gutterBottom
                          style={{ marginRight: 100 }}
                        >
                          Improved Customer Support
                        </Typography>
                        {/* <Typography variant="subtitle2" color="inherit">
                          <Box color="rgba(212,214,255,.95)">
                            Esistono innumerevoli variazioni
                          </Box>
                        </Typography> */}
                      </Box>
                    </CardContent>
                  </Card>
                </ScrollAnimate>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  )
}

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: 40,
    paddingTop: 15,
    minHeight: 100,
    // transition: "transform 0s ease-in-out",
    transition: "marginTop 0.2s ease-in-out",
    backgroundColor: "#0f123d",
    color: "#fff",
    borderRadius: 17,
    boxShadow: `0 0 12px 1px rgb(0 10 10 / 30%)`,

    "&:hover": {
      boxShadow: `0 0 12px 1px rgb(0 10 10 / 70%)`,
    },
    "&:hover $image": {
      transform: `scale(1.2)`,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 520,
    },
  },
  card2: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 50,
    },
  },
  card3: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 100,
    },
  },
  image: {
    transition: `transform .25s, visibility .25s ease-in`,
    position: "absolute",
    top: 2,
    right: 10,
  },
  marginLeft: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 80,
    },
  },
}))

export default ArtInt
