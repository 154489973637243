import React, { useEffect, useRef } from "react"
import {
  Typography,
  Button,
  ButtonBase,
  Container,
  Grid,
  Box,
  Paper,
  Avatar,
  makeStyles,
} from "@material-ui/core"
import BackgroundImage from "gatsby-background-image"
import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby"
import FormatQuoteOutlinedIcon from "@material-ui/icons/FormatQuoteOutlined"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

import Layout from "../components/layout"

// import styles from "../styles/Home.module.css";
import Hero from "../new/Hero"
import ArtInt from "../new/artint"
import Pulse from "../new/pulse"
import Genesis from "../new/genesis"
import Offerings from "../new/offerings"
// import Slider from "../components/slider/slider"

const params = {
  slidesPerView: 1,
  spaceBetween: 0,
  autoplay: {
    delay: 6000,
    disableOnInteraction: true,
  },
  loop: true,
  speed: 1000,
  navigation: {
    // nextEl: ".swiper-button-next",
    // prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    // dynamicBullets: true,
  },
  // effect: "fade",
}

function Clients({ clients }) {
  const clientsSwiper = {
    // slidesPerView: 3,
    spaceBetween: 0,
    freeMode: true,
    loop: true,
    loopedSlides: 4,
    speed: 6000,
    autoplay: {
      delay: 1,
      disableOnInteraction: false,
    },
    slidesPerView: 1,
    // allowTouchMove: false,
    centeredSlides: true,
    // pagination: {
    //   el: ".swiper-pagination",
    //   clickable: true,
    // },
    breakpoints: {
      // 1024: {
      //   slidesPerView: 4,
      //   spaceBetween: 40
      // },
      720: {
        slidesPerView: 3,
        // spaceBetween: 30
      },
    },
  }

  return (
    <>
      <Swiper {...clientsSwiper}>
        {clients.Companies.map(item => (
          <div>
            <img
              src={item.logo?.publicURL}
              alt={item.name}
              height="100px"
              className="clientImg"
            />
          </div>
        ))}
      </Swiper>
    </>
  )
}

const Home = () => {
  const classes = useStyles()

  const images = useStaticQuery(graphql`
    query {
      seo: strapiSeo {
        homepage {
          pagetitle
          description
          keywords
        }
      }
      home: allStrapiHome {
        edges {
          node {
            Sliders {
              title
              subtitle
              description
              ctaName
              ctaLink
              background {
                publicURL
              }
            }
          }
        }
      }
      testimonials: allStrapiTestimonial {
        edges {
          node {
            content
            job_title
            company
            image {
              publicURL
            }
          }
        }
      }
      client: allStrapiClients {
        edges {
          node {
            Companies {
              logo {
                publicURL
              }
              name
            }
          }
        }
      }
      heroImage: file(relativePath: { eq: "home/hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aiServiceImage: file(relativePath: { eq: "home/ai-service.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      analyticsServiceImage: file(
        relativePath: { eq: "home/analytics-service.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dataServiceImage: file(relativePath: { eq: "home/data-service.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      softwareServiceImage: file(
        relativePath: { eq: "home/software-service.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rpaServiceImage: file(relativePath: { eq: "home/rpa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      processServiceImage: file(relativePath: { eq: "home/process.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      clientsBg: file(relativePath: { eq: "clients/clientsbg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      client1: file(relativePath: { eq: "clients/client1.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      client2: file(relativePath: { eq: "clients/client2.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      client3: file(relativePath: { eq: "clients/client3.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout
      siteTitle={images.seo.homepage.pagetitle ?? "Home"}
      ogtitle="Simplifying Data-Based Decision Making using Technology!"
      description={
        images.seo.homepage.description ??
        "Explore what we can do in the space of Artificial Intelligence for your use case"
      }
      keywords={images.seo.homepage.keywords ?? ""}
    >
      <main>
        <Hero />
        <ArtInt />
        <Genesis />
        <Offerings />

        {/* CLIENTS */}
        <Box pt={5}>
          <BackgroundImage
            fluid={images.clientsBg.childImageSharp.fluid}
            style={{ backgroundPosition: "top" }}
          >
            <Box py={10}>
              <Container>
                <Typography
                  variant="h4"
                  color="primary"
                  align="center"
                  gutterBottom
                >
                  <Box style={{ fontVariant: "small-caps" }}>
                    <b>Our Clients</b>
                  </Box>
                </Typography>
                <Box pt={5}>
                  <Grid container spacing={3} justify="space-evenly">
                    {images.client.edges.map(clients => (
                      <Clients clients={clients.node} />
                    ))}
                  </Grid>
                </Box>
              </Container>
            </Box>
          </BackgroundImage>
        </Box>

        {/* TESTIMONIALS */}
        <Box py={10} overflow="hidden">
          <Container>
            <Typography
              variant="h4"
              color="primary"
              align="center"
              gutterBottom
            >
              <Box style={{ fontVariant: "small-caps" }}>
                <b>What Our Clients Say</b>
              </Box>
            </Typography>
          </Container>
          <Swiper {...params}>
            {images.testimonials.edges.map(item => (
              <div>
                <Container maxWidth="sm">
                  <Box position="relative" textAlign="center" mt={8}>
                    <Box
                      position="absolute"
                      right={-50}
                      top={-70}
                      zIndex={-1}
                      color="#00000015"
                    >
                      <FormatQuoteOutlinedIcon style={{ fontSize: 120 }} />
                    </Box>
                    <Typography variant="body1" gutterBottom>
                      <Box fontStyle="oblique">{item.node.content}</Box>
                    </Typography>
                    <Box display="flex" justifyContent="center">
                      <Avatar
                        alt={item.node.job_title}
                        src={item.node.image?.publicURL}
                        className={classes.avatar}
                      />
                    </Box>
                    <Typography variant="body1" color="primary" gutterBottom>
                      {item.node.job_title}
                    </Typography>
                    <Typography variant="h6" color="textPrimary" gutterBottom>
                      <b>{item.node.company}</b>
                    </Typography>
                  </Box>
                </Container>
              </div>
            ))}
          </Swiper>
        </Box>

        {/* <div style={{ position: "absolute", top: 1300, left: 30, zIndex: 10 }}>
          <Pulse />
        </div>
        <div style={{ position: "absolute", top: 1400, left: 60, zIndex: 10 }}>
          <Pulse />
        </div> */}
      </main>
    </Layout>
  )
}

// const Main1 = () => {
//   return (
//     <main>
//       <Hero />

//       <Box pt={5} />

//       <Box pt={5} />
//       {/* Our offerings */}
//       <div
//         style={{
//           height: 500,
//           backgroundPosition: "bottom top",
//           // background: "rgb(2,0,36)",
//           background: `linear-gradient(90deg, rgba(2,0,36,.8) 0%, rgba(9,52,115,.9) 100%)`,
//         }}
//       >
//         <Grid container>
//           <Grid item md={4}>
//             Our Offerings {JSON.stringify(process)}
//           </Grid>
//           <Grid
//             item
//             md={8}
//             style={{
//               height: 500,
//               backgroundPosition: "bottom top",
//               // background: "rgb(2,0,36)",
//               background: `linear-gradient(90deg, rgba(2,0,36,.8) 0%, rgba(9,52,115,.9) 100%), url(${process.src}) no-repeat`,
//             }}
//           >
//             <Box p={5}>
//               <Grid container alignItems="center">
//                 <Grid item md={6}>
//                   <Box p={3}>
//                     <a href="#" className="card card-10 stacked--fan-right">
//                       <div className="content">
//                         <code className="title">Process Consulting</code>
//                       </div>
//                     </a>
//                   </Box>
//                 </Grid>
//                 <Grid item md={6}>
//                   <Box p={3}>
//                     <a href="#" className="card card-10 stacked--fan-right">
//                       <div className="content">
//                         <code className="title">Analytics</code>
//                       </div>
//                     </a>
//                   </Box>
//                 </Grid>
//               </Grid>
//             </Box>
//           </Grid>
//         </Grid>
//       </div>
//     </main>
//   )
// }

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: "auto",
  },
}))

export default Home
