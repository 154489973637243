import React from "react"
import { Link as GatsbyLink } from "gatsby"
import {
  Box,
  Container,
  Button,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"

import aivideo from "./hero-slide1.mp4"
import genesisvideo from "./hero-slide2.mp4"
import pactsvideo from "./hero-slide3.mp4"
import MouseDown from "./mousedown"

const Content1 = ({ title, subtitle, desc, cta, link, bgVideo, external }) => {
  const classes = useStyles()

  return (
    <>
      <Box
        style={{
          width: "100vw",
          // backgroundImage: `linear-gradient(to bottom, rgba(9,52,115,1), rgba(9,52,115,0.8), rgba(9,52,115,0.5)), url('/hero-fin.mp4')})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          //   background: "red",
        }}
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        // className={classes.hero}
      >
        <div style={{ position: "absolute", height: "100%", width: "100%" }}>
          <video
            autoPlay
            loop
            muted
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              minHeight: "100%",
              minWidth: "100%",
              maxHeight: "100%",
              objectFit: "cover",
              objectPosition: "center center",
              zIndex: -100,
            }}
          >
            <source src={bgVideo} type="video/mp4" />
          </video>
        </div>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item md={6} justify="center" alignItems="center">
              <Box py={5}>
                <Typography
                  variant="h1"
                  color="secondary"
                  style={{ textShadow: "3px 3px #000" }}
                  data-sal="slide-right"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                >
                  <Box color="white" className={classes.title}>
                    <b>{title}</b>
                  </Box>
                </Typography>
                <Typography
                  variant="h3"
                  gutterBottom
                  color="secondary"
                  data-sal="slide-right"
                  data-sal-delay="400"
                  data-sal-easing="ease"
                >
                  <Box color="white" style={{ fontSize: "2.5rem" }}>
                    <small>{subtitle}</small>
                  </Box>
                </Typography>

                {/* Desc */}
                <Box pt={2} color="info.contrastText">
                  <Typography
                    variant="h5"
                    gutterBottom
                    data-sal="slide-right"
                    data-sal-delay="500"
                    data-sal-easing="ease"
                  >
                    <Box color="lightgray" style={{ fontSize: "1.3rem" }}>
                      {desc}
                    </Box>
                  </Typography>
                </Box>

                {/* CTA */}
                <Box
                  pt={6}
                  data-sal="slide-up"
                  data-sal-delay="600"
                  data-sal-easing="ease"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    className={classes.button}
                    component={GatsbyLink}
                    endIcon={<ArrowForwardIcon />}
                    to={link}
                    target={external ? "_blank" : "_self"}
                  >
                    {cta}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <MouseDown />
        </Container>
      </Box>
    </>
  )
}

const Hero = () => {
  const params = {
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true,
    },
    loop: true,
    grabCursor: true,
    speed: 1000,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    // noSwiping: true,
    // effect: "creative",
  }

  return (
    <section>
      <Swiper {...params}>
        <div>
          <Content1
            title="Artificial Intelligence"
            subtitle="is the future!"
            desc="Business relevance is all about timing adoption"
            cta="How we can help"
            link="/artificial-intelligence"
            bgVideo={aivideo}
            external={false}
          />
        </div>
        <div>
          <Content1
            title="Genesis"
            subtitle="IOT - Operations Management"
            desc="An exception-based Operations Management Solution"
            cta="Explore"
            link="/product/genesis"
            bgVideo={genesisvideo}
            external={false}
          />
        </div>
        <div>
          <Content1
            title="PACTS"
            subtitle="Are you looking for a quick consult or technical support?"
            desc="phAIdelta Automation, Consulting and Technology Services - Let us know about it and you can count on us to make it work!"
            cta="Log a support request"
            link="https://pacts.phaidelta.com/"
            bgVideo={pactsvideo}
            external={true}
          />
        </div>
      </Swiper>
    </section>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: 45,
    // backgroundColor: '#fd435a',
    boxShadow: `0 0 0 6px rgb(255 255 255 / 25%)`,
    transition: `box-shadow .2s,color .2s,background-color .2s`,
  },
  title: {
    fontSize: "5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.5rem",
    },
  },
}))

export default Hero
