import React from "react"
import "./mousedown.css"

const MouseDown = () => {
  return (
    <div className="icon-scroll">
      <div className="mouse">
        <div className="wheel"></div>
      </div>
      <div className="icon-arrows">
        <span></span>
      </div>
    </div>
  )
}

export default MouseDown
